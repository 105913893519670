import React, { useState } from "react";
import Modal from "react-modal";
import { GravityForm, gravityFormExtractor } from "../gravity-form";
import { useAllGravityForms } from "../../hooks";
import "./ModalForm.scss";

Modal.defaultStyles.overlay.zIndex = "99";
Modal.defaultStyles.overlay.backgroundColor = "red;";

Modal.setAppElement("#layout");

export const ModalForm = ({ buttonText = "Enquire now", formId = 2 }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const gravityFormsData = useAllGravityForms();

  const formData = gravityFormExtractor(formId, gravityFormsData);

  return (
    <div className="request-quote">
      <a onClick={() => setIsOpen(true)} className="button-filled">
        <span className="button__text">{buttonText}</span>
      </a>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Example Modal"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "40px",
            left: "40px",
            right: "40px",
            bottom: "40px",
            border: "1px solid #052337",
            background: "#052337",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <button onClick={closeModal} className="modal__close">
          X
        </button>
        <GravityForm
          formData={formData}
          displayDescription={true}
          dataLayerEvent={"form_submit_success"}
        />
      </Modal>
    </div>
  );
};
