import React from "react";
import "./TabbedContent.scss";
import RenderContent from "../RenderContent";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ReactPlayer from "react-player/youtube";

export const TabbedContent = ({ content }) => {
  return (
    <div className="tabbed-content">
      <Tabs>
        {/* tabbed header */}
        <TabList>
          {Object.keys(content).length > 0 &&
            content?.map((tab, index) => {
              return <Tab key={index}>{tab.tabTitle.label}</Tab>;
            })}
        </TabList>

        {/* Tab content */}
        {Object.keys(content).length > 0 &&
          content?.map((tab, index) => {
            return (
              <TabPanel key={index}>
                {tab?.contentType === "text" && (
                  <RenderContent content={tab.content} />
                )}
                {tab?.contentType === "video" && (
                  <div className="tabbed-content__video">
                    <ReactPlayer
                      url={tab.videoUrl}
                      className="react-player"
                      width="100%"
                      height="100%"
                    />
                  </div>
                )}
              </TabPanel>
            );
          })}
      </Tabs>
    </div>
  );
};
