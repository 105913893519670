import React, { useState, useEffect } from "react";
import "./CarouselThumbnail.scss";
import { ImagePass } from "../Image/ImagePass";
import ReactPlayer from "react-player/youtube";

export const CarouselThumbnail = ({ items }) => {
  if (!items && items === null) {
    return null;
  }
  const [heroItem, setHeroItem] = useState(0);

  const makeHeroItem = (newHeroItem) => {
    setHeroItem(newHeroItem);
  };

  return (
    <div className="carouselThumbnail">
      <div className="carouselThumbnail--main">
        <button
          className="swiper-button-prev"
          onClick={() => setHeroItem(heroItem - 1)}
          disabled={heroItem === 0}
        />
        <button
          className="swiper-button-next"
          onClick={() => setHeroItem(heroItem + 1)}
          disabled={heroItem === items.length - 1}
        />
        {Object.keys(items).length > 0 &&
          items?.map((item, index) => {
            if (item?.mediaType === "image") {
              return (
                <div
                  className={`carouselThumbnail--mainImage ${
                    heroItem === index ? "carouselThumbnail--active" : ""
                  }`}
                  key={index}
                >
                  <ImagePass src={item?.image} key={index} />
                </div>
              );
            }

            if (item?.mediaType === "video") {
              return (
                <div
                  className={`carouselThumbnail--mainVideo ${
                    heroItem === index ? "carouselThumbnail--active" : ""
                  }`}
                  key={index}
                >
                  <ReactPlayer
                    url={item?.videoUrl}
                    className="react-player"
                    width="100%"
                    height="100%"
                  />
                </div>
              );
            }
          })}
      </div>
      <div className="carouselThumbnail--thumbnails">
        {Object.keys(items).length > 0 &&
          items?.map((item, index) => {
            if (item?.mediaType === "image") {
              return (
                <div
                  className={`carouselThumbnail--thumbnail ${
                    heroItem === index
                      ? "carouselThumbnail--thumbnail-hidden"
                      : ""
                  }`}
                  key={index}
                  onClick={() => makeHeroItem(index)}
                >
                  <ImagePass src={item?.image} key={index} />
                </div>
              );
            }

            if (item?.mediaType === "video") {
              return (
                <div
                  className={`carouselThumbnail--thumbnail carouselThumbnail--thumbnail--video ${
                    heroItem === index
                      ? "carouselThumbnail--thumbnail-hidden"
                      : ""
                  }`}
                  key={index}
                  onClick={() => makeHeroItem(index)}
                >
                  <ImagePass
                    src={item?.videoThumbnail?.source_url}
                    key={index}
                  />
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};
