import React from "react";
import { graphql } from "gatsby";
import Previewable from "../components/Previewable";
import SEO from "../components/SEO";
import { decodeEntities, stripHtmlTags } from "../utils/helpers";
import { ProductPostTemplate } from "../components/ProductPostTemplate";
import { ContactBlock } from "../components/Acf/ContactBlock";

const ProductPost = (props) => {
  const { data, location } = props;
  const { wordpressPost: post, wordpressWpSettings, siteSettings } = data;
  const { wordpressUrl } = wordpressWpSettings;
  if (!post) return null;
  const {
    title,
    content,
    excerpt,
    featured_media,
    yoast,
    categories,
    date,
    author,
    wordpress_id,
  } = post;
  return (
    <>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(
          wordpressWpSettings.title
        )}`}
        desc={excerpt ? stripHtmlTags(excerpt) : null}
        banner={
          featured_media &&
          featured_media.source_url &&
          featured_media.source_url
        }
        location={location}
      />
      <ProductPostTemplate
        location={location}
        post={post}
        blogSlug="products"
        siteSettings={siteSettings}
      />
      <ContactBlock />
    </>
  );
};

export default Previewable(ProductPost, "products");

export const pageQuery = graphql`
  fragment ProductFields on wordpress__wp_products {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query ProductPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions {
      options {
        enquireNowButton
        downloadButton
        hireNowButton
      }
    }
    wordpressPost: wordpressWpProducts(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 670, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      acf {
        productGallery {
          mediaType
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 670, quality: 70) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          videoUrl
          videoThumbnail {
            source_url
          }
        }
        tabbedContent {
          content
          contentType
          videoUrl
          tabTitle {
            value
            label
          }
        }
        productFiles {
          pdf {
            source_url
          }
          pdfName
          gatedFile
        }
        hireButton
      }
      product_category
      yoast {
        metaTitle: title
        metaDescription: metadesc
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
    }
  }
`;
