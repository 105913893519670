import React, { useState } from "react";
import Modal from "react-modal";
import { GravityForm, gravityFormExtractor } from "../gravity-form";
import { useAllGravityForms } from "../../hooks";
import "./PdfDownload.scss";

Modal.defaultStyles.overlay.zIndex = "99";
Modal.setAppElement("#layout");

const FileList = ({ files }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const gravityFormsData = useAllGravityForms();

  const formData = gravityFormExtractor(3, gravityFormsData);

  return (
    Object.keys(files).length > 0 &&
    files?.map((file, index) => {
      return (
        <React.Fragment key={index}>
          <li className="pdf-download__list__item">
            {file.gatedFile ? (
              // option for gated content which will open the modal with form
              <a onClick={() => setIsOpen(true)}>{file.pdfName}</a>
            ) : (
              // option for non-gated content, open in new tab
              <a href={file?.pdf?.source_url} target="_blank" rel="noreferrer">
                {file.pdfName}
              </a>
            )}
          </li>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setIsOpen(false)}
            contentLabel="Example Modal"
          >
            <button onClick={closeModal} className="modal__close">
              X
            </button>
            <GravityForm
              formData={formData}
              displayDescription={true}
              dataLayerEvent={"form_submit_success"}
              fileToOpen={file?.url}
            />
          </Modal>
        </React.Fragment>
      );
    })
  );
};

export const PdfDownload = ({ files, buttonText = "Download PDF" }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="pdf-download">
      <div
        onClick={() => setIsOpen((isOpen) => !isOpen)}
        className="button-outlined pdf-download__button"
      >
        <span className="button__text">{buttonText}</span>
      </div>
      <ul className={`pdf-download__list ${isOpen ? "open" : ""}`}>
        <FileList files={files} />
      </ul>
    </div>
  );
};
