import React from "react";
import { graphql } from "gatsby";
import { ImagePass } from "../Image/ImagePass";
import RenderContent from "../RenderContent";
import { Breadcrumbs } from "../Breadcrumbs";
import { TabbedContent } from "../TabbedContent";
import { ModalForm } from "../ModalForm";
import { PdfDownload } from "../PdfDownload";
import { CarouselThumbnail } from "../CarouselThumbnail";
import "./ProductPostTemplate.scss";
import ReactPlayer from "react-player/youtube";

export const ProductPostTemplate = ({ post, location, siteSettings }) => {
  const { wordpress_id, title, content, date, categories, acf } = post;

  const getCategory = (location) => {
    //
    // returns the category of the item from the URL structure
    //
    const pathArray = location.pathname.split("/");
    const filteredArray = pathArray.filter((v) => v != "");
    const category = filteredArray.slice(-2, -1)[0];
    return category.replaceAll("-", " ");
  };

  return (
    <section className="product-post layout">
      <div className="product-post__wrapper">
        <Breadcrumbs location={location} />
        <div className="product__info">
          <div className="product__images">
            <CarouselThumbnail items={acf?.productGallery} />
          </div>
          <div className="product__description">
            <span className="eyebrow product__eyebrow">
              {getCategory(location)}
            </span>
            <h1 className="product__title">{title}</h1>
            <RenderContent content={content} />
            <TabbedContent content={acf.tabbedContent} />
            <div className="product__buttons">
              <ModalForm
                buttonText={siteSettings?.options?.enquireNowButton}
                formId={2}
              />
              {acf.hireButton && siteSettings.options?.hireNowButton && (
                <ModalForm
                  buttonText={siteSettings.options?.hireNowButton}
                  formId={5}
                />
              )}
              {acf.productFiles && acf.productFiles.length > 0 && (
                <PdfDownload
                  files={acf.productFiles}
                  buttonText={siteSettings?.options?.downloadButton}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
